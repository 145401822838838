<bui-navlist variant="dark">
  <bui-navlist-item
    link="/dashboard"
    label="Dashboard"
    icon="dashboard"
    (click)="onNavlistItemClick()"
  />
  <bui-navlist-item
    link="/services"
    label="Services"
    icon="fueling-truck"
    (click)="onNavlistItemClick()"
  />
  <bui-navlist-item
    *ngIf="isBillingPageVisible$ | async"
    link="/billing"
    label="Billing"
    icon="invoice"
    (click)="onNavlistItemClick()"
  />
  <bui-navlist-item
    *ngIf="isSitesPageVisible$ | async"
    link="/sites"
    label="Sites"
    icon="building"
    (click)="onNavlistItemClick()"
  />
  <bui-navlist-item
    link="/asset-management"
    label="Assets"
    icon="truck"
    (click)="onNavlistItemClick()"
  />

  @if (!isSaas) {
    <bui-navlist-item
      link="/reports"
      label="Reports"
      icon="calendar-clock"
      (click)="onNavlistItemClick()"
    />
  }

  <bui-navlist-item
    *ngIf="!isLimitedMember"
    link="/settings"
    label="Settings"
    icon="settings"
    (click)="onNavlistItemClick()"
  />
  <bui-navlist-item
    *ngIf="canSeeUsers"
    link="/users"
    label="Users"
    icon="user"
    (click)="onNavlistItemClick()"
  />
  <bui-navlist-item
    link="/updates"
    label="Updates"
    icon="updates"
    (click)="onNavlistItemClick()"
  />

  <ng-container *ngIf="variant === 'floating'">
    <div class="divider"></div>

    <bui-navlist-item
      link="/my-profile"
      label="My Profile"
      icon="user"
      (click)="onNavlistItemClick()"
    />

    <bui-navlist-item label="Log out" icon="logout" (click)="onLogoutClick()" />
  </ng-container>
</bui-navlist>
